import React, {Component} from "react";

//theme
//core
import "../rubick.css"
import {Toast, Row, Col} from "@nutui/nutui-react";
import axios from "axios";
import {changeTitleType, eventReport, log, setTitle, toast} from "../../RubickJSBridge";
import {_baseApiUrl} from "../../Config";
import "../rubick.css"

import * as echarts from 'echarts';
import 'echarts/lib/chart/bar'; // 导入柱状图
import 'echarts/lib/component/tooltip'; // 导入提示框组件
import 'echarts/lib/component/title';
import { NavLink} from "react-router-dom";


export class BarChart extends Component {


    chartRef = React.createRef();

    constructor(props) {
        super(props);
        this.data = this.props.data.reverse();
        this.labels = this.props.labels.reverse();
        this.type = this.props.type
        if (this.type === null || this.type === undefined){
            this.type = "bar"
        }
        this.title = this.props.title
        this.color = this.props.color
        changeTitleType(1)


    }

    componentDidMount() {


        const chart = echarts.init(this.chartRef.current);

        // 将最后一个数的样式设置为红色
        const seriesData = this.data.map((value, index) => {
            return {
                value: value,
                itemStyle: index === this.data.length - 1 ? {color: 'red'} : null,
            };
        });

        const minValue = Math.floor(Math.min(...this.data) * 0.8); // 获取最小值
        const maxValue =Math.ceil( Math.max(...this.data) ); // 获取最大值
        // 配置项
        const option = {
            color: this.color,
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                }
            },
            title: {
                text: this.title,
                textStyle: {
                    fontSize: "12pt",
                },
                left: "30vw",

            },


            xAxis: {
                type: 'category',
                show: true, // 去除横坐标示例
                data: this.labels,
                splitLine: {
                    show: false,
                }
            },
            yAxis: {
                min: minValue, // 设置y轴最小值
                max: maxValue, // 设置y轴最大值
                show: true, // 去除纵坐标示例
                boundaryGap: [0, '100%'],
                type: 'value',
                splitLine: {
                    show: false,
                }

            },

            series: [{
                type: this.type,
                itemStyle: {
                    borderRadius: [2, 2, 2, 2], // 设置柱状图圆角，分别对应左上、右上、右下、左下
                },
                data: seriesData, // 替换为你的数据
            }],
        };

        chart.setOption(option);
        // 当组件卸载时销毁图表
        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {

        return <div
            ref={this.chartRef}
            style={{
                width: '100vw',
                height: '60vw',
                overflow: 'auto', // 添加滚动样式
                marginLeft: "-5vw",
            }}
        />;
    }
}


export class TenDataCommonV2Component extends React.Component {

    chartRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            aid: props.aid,
            mData: {},
            type :'bar',
        }

    }

    getList() {
        // Toast.loading("")
        this.currentId = this.state.aid
        eventReport("pv", "tenData", this.currentId)

        axios.post(_baseApiUrl + "api/coreData/data/post", {"provider": this.currentId})

            .then(response => {
                console.log("resp", response.data.data)
                let data = response.data.data
                setTitle(data.data.name)
                this.setState({
                    mData: data
                })

            })
            .catch(function (error) {
                log(error.toString())
                // Toast.clear()
                toast("请求失败请重试", "error")
            })
    }

    //头部数据

    header() {
        let data = this.state.mData.data
        let labels = this.state.mData.labels
        return (
            <div>
                <div className={"r_title2"}>{data.name}</div>
                <div className={"r_card"}
                     style={{backgroundColor: '#181818', height: "40vw", marginTop: "6vw", position: "relative"}}>
                    <div className={"r_gray"}
                         style={{position: "absolute", right: "10pt", top: "10pt",
                             backgroundColor: "rgba(94,255,231,0.2)", // 半透明背景颜色（黑色，透明度50%）
                             borderRadius: "10px", // 圆角半径
                             padding: "5px 10px", // 内边距
                             color: "#fff" // 文字颜色（白色）

                    }}>更新频次：{labels.rate}
                    </div>
                    <div className={"r_gray"}
                         style={{position: "absolute", right: "10pt", bottom: "10pt"}}>{data.date}</div>
                    <div className={"r_gray"}
                         style={{position: "absolute", left: "10pt", bottom: "10pt",
                             backgroundColor: "rgba(255,94,94,0.48)", // 半透明背景颜色（黑色，透明度50%）
                             borderRadius: "10px", // 圆角半径
                             padding: "5px 10px", // 内边距
                             color: "#fff" // 文字颜色（白色）
                    }}>来源: {labels.source}</div>
                    <div className={"r_gray"}
                         style={{position: "absolute", left: "6vw", display: "flex", height: "38vw"}}>
                        <div style={{margin: "auto"}}>
                            <div className={"r_gray"}>今值</div>
                            <div className={"r_main_count"} style={{
                                marginTop: "6pt",
                                fontSize:"10vw"
                            }}>{data.current}</div>
                        </div>
                    </div>
                    <div style={{position: "absolute", left: "50vw", top: "0vw", display: "flex", height: "38vw"}}>
                        <div style={{margin: "auto"}}>
                            <div className={"r_gray"}>前值</div>
                            <div className={"r_sub_count"} style={{marginTop: "10pt"}}>{data.front}</div>
                            <div className={"r_gray"} style={{marginTop: "10pt"}}>预测值</div>
                            <div className={"r_sub_count"} style={{marginTop: "10pt"}}>{data.guess}</div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }


    bottom() {
        return (<div style={{height: "14vw", display: "flex", alignItems: "center", justifyContent: "center"}}
                     className={"r_gray2"}>
            *数据来源互联网，以实际数据为准
        </div>)
    }

    others() {

        const paths = [{"title": "中国PPI年率", "path": "td.ppi_y"}
            , {"title": "中国CPI月率", "path": "td.cpi_m"}
            , {"title": "中国CPI年率", "path": "td.cpi_y"}
            , {"title": "中国以美元计算出口年率", "path": "td.exp_y"}
            , {"title": "中国以美元计算进口年率", "path": "td.in_y"}
            , {"title": "中国以美元计算贸易帐", "path": "td.maoyi"}
            , {"title": "中国规模以上工业增加值年率", "path": "td.gongyeup"}
            , {"title": "中国财新制造业PMI终值", "path": "td.pmi_caixin_zzy"}
            , {"title": "中国财新服务业PMI", "path": "td.pmi_caixin_fwy"}
            , {"title": "中国外汇储备(美元)", "path": "td.whcb"}
            , {"title": "中国M2货币供应年率", "path": "td.m2_y"}
            , {"title": "中国GDP年率", "path": "td.gdp_y"}
            , {"title": "中国官方制造业PMI", "path": "td.pmi_zzy"}
            , {"title": "美联储利率", "path": "td.dc_usa_interest_rate_decision"}
            , {"title": "澳洲联储利率", "path": "td.dc_australia_interest_rate_decision"}
            , {"title": "英国央行利率", "path": "td.dc_english_interest_rate_decision"}
            , {"title": "印度利率", "path": "td.dc_india_interest_rate_decision"}
            , {"title": "俄罗斯利率", "path": "td.dc_russia_interest_rate_decision"}
            , {"title": "巴西利率", "path": "td.dc_brazil_interest_rate_decision"}
            , {"title": "瑞士央行利率", "path": "td.dc_switzerland_interest_rate_decision"}
            , {"title": "欧洲央行利率", "path": "td.dc_interest_rate_decision"}
            , {"title": "日本利率", "path": "td.dc_japan_interest_rate_decision"}
            , {"title": "美国GDP", "path": "td.dc_usa_gdp"}
            , {"title": "美国失业率", "path": "td.dc_usa_unemployment_rate"}
            , {"title": "美国CPI月率", "path": "td.dc_usa_cpi"}
            , {"title": "欧元区季度GDP年率", "path": "td.dc_eurozone_gdp_yoy"}
            , {"title": "欧元区CPI月率", "path": "td.dc_eurozone_cpi_mom"}
            , {"title": "欧元区CPI年率", "path": "td.dc_eurozone_cpi_yoy"}
            , {"title": "欧元区PPI月率", "path": "td.dc_eurozone_ppi_mom"}]
        console.log("paths=" + paths)
        let os = paths.map((data, index) => {
            // const mPath = data.path.replace(/td./g, '');
            return (<div key={index} className={"r_blue"} style={{marginTop: "1vw", fontSize: "13pt"}} onClick={e => {
                window.scrollTo(0, 0)
                this.setState({
                    aid: data.path
                })
            }}>{data.title}</div>)
        })

        return (<div style={{marginTop: "10vw"}}>
            <Row type="flex" justify="space-between">
                <Col span="18">
                    <div className={"r_title2"}>相关推荐</div>
                </Col>
            </Row>

            {os}

        </div>)
    }

    labels() {
        let labels = this.state.mData.labels


        return (<div style={{marginTop: "10vw"}}>
            <Row type="flex" justify="space-between">
                <Col span="18">
                    <div className={"r_title2"}>数据解释</div>
                </Col>
            </Row>
            <div className={"r_gray"} style={{
                marginTop: "2vw",
                whiteSpace: "pre-line",
                width: "90vw",
                fontSize: "12pt"
            }}>{labels.dataInterpretation}</div>
            <div className={"r_gray"}
                 style={{marginTop: "4vw", whiteSpace: "pre-line", width: "90vw", fontSize: "12pt"}}>名词释义
            </div>
            <div className={"r_gray"} style={{
                marginTop: "2vw",
                whiteSpace: "pre-line",
                width: "90vw",
                fontSize: "12pt"
            }}>{labels.wordInterpretation}</div>
            <div className={"r_gray"}
                 style={{marginTop: "4vw", whiteSpace: "pre-line", width: "90vw", fontSize: "12pt"}}>解读公式
            </div>
            <div className={"r_gray"} style={{
                marginTop: "2vw",
                whiteSpace: "pre-line",
                width: "90vw",
                fontSize: "12pt"
            }}>{labels.formula}</div>
            <div className={"r_gray"}
                 style={{marginTop: "4vw", whiteSpace: "pre-line", width: "90vw", fontSize: "12pt"}}>潜在影响
            </div>
            <div className={"r_gray"} style={{
                marginTop: "2vw",
                whiteSpace: "pre-line",
                width: "90vw",
                fontSize: "12pt"
            }}>{labels.effect}</div>
        </div>)
    }

    chart() {
        let values = this.state.mData.values
        let keys = this.state.mData.keys
        let labels = values.map(value => {
            return value[0]
        })


        return (
            <div style={{marginTop: "10vw"}}>
                <Row type="flex" justify="space-between"
                     style={{display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center"}}
                >
                    <Col span="16">
                        <div className={"r_title2"}>数据趋势</div>
                    </Col>
                    {/*<Col span="2">*/}
                    {/*    <FontAwesomeIcon icon={faChartBar} size={"lg"} onClick={event => {*/}

                    {/*        console.log("type change bar。。。")*/}
                    {/*        this.setState({*/}
                    {/*            type:'bar'*/}
                    {/*        })*/}
                    {/*    }}/>*/}
                    {/*</Col>*/}
                    {/*<Col span="2">*/}
                    {/*    <FontAwesomeIcon icon={faChartLine}  size={"lg"} onClick={event => {*/}
                    {/*        console.log("type change line。。。")*/}

                    {/*        this.setState({*/}
                    {/*            type:'line'*/}
                    {/*        })*/}
                    {/*    }}/>*/}
                    {/*</Col>*/}
                    <Col span="6">
                        <div className="r_gray" style={{display: "flex", justifyContent: "right", fontSize: "13pt",}}>
                            <NavLink to={{pathname: '/core_data/tenList', search: "aid=" + this.state.aid}}
                                     className={"r_gray"} style={{
                                textDecoration: "none",
                            }}>数据明细</NavLink>
                        </div>
                    </Col>
                </Row>
                <BarChart title={keys[1].name} color={"#DC6B66"} labels={labels} type={this.state.type} data={values.map(value => {
                    return value[1]
                })}></BarChart>
                <BarChart title={keys[2].name} color={"#749AA0"} labels={labels} type={this.state.type}  data={values.map(value => {
                    return value[2]
                })}></BarChart>
                <BarChart title={keys[3].name} color={"#8DC0A9"} labels={labels} type={this.state.type}  data={values.map(value => {
                    return value[3]
                })}></BarChart>
            </div>
        )
    }


    render() {
        if (this.state.mData.data === undefined || this.currentId === undefined || this.currentId !== this.state.aid) {
            this.getList()
            return <></>
        }

        return (
            <div>
                {/*<iframe src={"http://m.fushun.bendibao.com/news/youjiachaxun/" } style={{width:"100vw",height:"100vh"}}></iframe>*/}
                <div className={"r_page"}>

                    <nav>
                        {this.header()}
                        {this.chart()}
                        {this.labels()}
                        {this.others()}
                        {this.bottom()}
                    </nav>
                </div>
            </div>

        )
    }


}
