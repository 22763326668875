import React from 'react';
import { Collapse, CollapseItem } from '@nutui/nutui-react';

const TripDataInfo = () => {
    document.title = "行程数据说明"

    return (
        <div style={{ 
            padding: '20px', 
            maxWidth: '800px', 
            margin: '0 auto',
            minHeight: '100vh',
            background: '#181818',
            color: '#ffffff'
        }}>
            <h1 style={{
                color: '#ffffff',
                fontSize: '24px',
                marginBottom: '20px'
            }}>行程数据说明</h1>

            {/* 远程数据和本地数据说明 */}
            <div style={{
                background: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '12px',
                padding: '16px',
                marginBottom: '20px'
            }}>
                <h2 style={{
                    fontSize: '18px',
                    marginBottom: '16px',
                    color: 'rgba(255, 255, 255, 0.9)'
                }}>远程数据</h2>
                <p style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    lineHeight: '1.6',
                    marginBottom: '8px'
                }}>
                    远程数据使用的是车辆上传的行程数据,仅支持ID7以上系统,需要开启车辆行程记录(含分析)上报。
                </p>
                <p style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    lineHeight: '1.6'
                }}>
                    远程数据根据车辆登录的ID记录,非本BMW ID的行程不会被记录。
                </p>
            </div>

            <div style={{
                background: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '12px',
                padding: '16px',
                marginBottom: '20px'
            }}>
                <h2 style={{
                    fontSize: '18px',
                    marginBottom: '16px',
                    color: 'rgba(255, 255, 255, 0.9)'
                }}>本地数据</h2>
                <p style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    lineHeight: '1.6',
                    marginBottom: '8px'
                }}>
                    本地数据使用手机定期获取的车辆信息进行计算分析得出的行程。
                </p>
                <p style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    lineHeight: '1.6',
                    marginBottom: '8px'
                }}>
                    分析的准确性依赖于本地获取车辆数据的及时性。
                </p>
                <p style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    lineHeight: '1.6',
                    marginBottom: '8px'
                }}>
                    时间间隔很近的两次行程会被当做一次行程。
                </p>
                <p style={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    lineHeight: '1.6'
                }}>
                    行程开始和结束时间可能存在几分钟误差。
                </p>
            </div>

            {/* 常见问题 */}
            <Collapse style={{
                '--nutui-collapse-item-background': '#181818',
                '--nutui-collapse-item-border-color': 'rgba(255, 255, 255, 0.1)',
                '--nutui-collapse-item-title-color': 'rgba(255, 255, 255, 0.9)',
                '--nutui-collapse-item-content-color': 'rgba(255, 255, 255, 0.7)',
                '--nutui-collapse-wrapper-content-padding': '16px'
            }}>
                {[
                    {
                        title: '为什么行程有些有轨迹,有些没有轨迹?',
                        content: `行程轨迹依赖于手机是否更新到行程过程中>3次车辆数据;同时行程轨迹会使用手机定位进行补全行程轨迹。\n如果行程过短或者手机未能正确更新到车辆信息,则无法显示轨迹`
                    },
                    {
                        title: '为什么本地数据和远程数据有差异?',
                        content: `本地数据依据本地记录到的车辆信息进行分析;分析准确性依赖信息是否丰富。\n本地数据不区分车辆登录的ID,所以有可能本地数据有的行程,远程数据没有。\n如果某次行程过程中,手机未获取到车辆信息.则仅能记录到行程起始点,无法计算准确行程开始结束时间。`
                    },
                    {
                        title: 'IOS如何提高行程准确性?',
                        content: `1. 必须开启软件的定位权限为[始终]\n2. 如果对行程精度要求较高,则在 [设置-策略管理] 中设置为: 高精度策略\n3. ID7 及以上车型开启车辆行程和数据上报`
                    },
                    {
                        title: 'Android如何提高行程准确性?',
                        content: `1. 忽略电池优化保证可以定期请求车辆数据\n2. 允许应用自启动【华为手机关闭自动管理，允许自启动，允许后台活动】\n3. ID7 及以上车型开启车辆行程和数据上报`
                    },
                    {
                        title: '如何开启车辆行程上报?',
                        content: `1.在你还坐在你的宝马(BMW)期间，转到"应用">"已安装的应用"，以下载最新版本的宝马(BMW)应用。为此在iDrive控制器上按压右下方选项，并选择"更新应用和服务"。\n2.转到"我的座驾">"设置"并选择"常规设置"。点击"数据保护"并选择"所有服务(含分析)"。如有需要，也可改为选择"个人选择"并启用"我的行程"选项。\n3.前往你的 BMW 并在显示器上选择你的驾驶员档案。确保使用你的 BMW ID 登录`
                    }
                ].map((item, index) => (
                    <CollapseItem 
                        title={item.title} 
                        name={index}
                        key={index}
                    >
                        {item.content.split('\n').map((text, i) => (
                            <p key={i} style={{ marginBottom: i < item.content.split('\n').length - 1 ? '8px' : '0' }}>
                                {text.trim()}
                            </p>
                        ))}
                    </CollapseItem>
                ))}
            </Collapse>
        </div>
    );
};

export default TripDataInfo; 