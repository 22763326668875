import React from "react";
import {changeTitleType, eventReport, log, setTitle, toast} from "../../RubickJSBridge";
import {Table} from "@nutui/nutui-react";
import axios from "axios";
import {_baseApiUrl} from "../../Config";

export class YoujiaV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: '省份',
                    key: 'province',
                    align: 'left',
                    render: (record) => {
                        return <span style={{ 
                            color: '#FFFFFF', 
                            fontSize: '15px', 
                            fontWeight: '600',
                            paddingLeft: '16px' 
                        }}>{record.province}</span>
                    }
                },
                {
                    title: '#92',
                    key: '92',
                    align: 'center',
                    render: (record) => {
                        return <span style={{ color: '#E64340', fontSize: '14px', fontWeight: '500' }}>{record['92']}</span>
                    }
                },
                {
                    title: '#95',
                    key: '95',
                    align: 'center',
                    render: (record) => {
                        return <span style={{ color: '#E64340', fontSize: '14px', fontWeight: '500' }}>{record['95']}</span>
                    }
                },
                {
                    title: '#98',
                    key: '98',
                    align: 'center',
                    render: (record) => {
                        return <span style={{ color: '#E64340', fontSize: '14px', fontWeight: '500' }}>{record['98']}</span>
                    }
                },
                {
                    title: '#0',
                    key: '0',
                    align: 'center',
                    render: (record) => {
                        return <span style={{ color: '#909399', fontSize: '14px', fontWeight: '500' }}>{record['0']}</span>
                    }
                }
            ],
            mData: [],
            updateTime: ''
        }
        setTitle("油价信息")
        changeTitleType(1)
        eventReport("pv", "youjia")
    }

    componentDidMount() {
        this.getList()
    }

    getList() {
        axios.post(_baseApiUrl + "api/coreData/data/post", {"provider": "youjia"})
            .then(response => {
                let data = response.data.data
                this.setState({
                    mData: Object.values(data).reverse(),
                    updateTime: data['北京'].date || ''
                })
            })
            .catch(function (error) {
                log(error.toString())
                toast("请求失败请重试", "error")
            })
    }

    bottom() {
        return (
            <div style={{
                padding: "16px",
                textAlign: "center",
                color: "var(--nutui-text-color-2)",
                fontSize: "14px"
            }}>
                *数据来源互联网，以实际数据为准
            </div>
        )
    }

    render() {
        return (
            <div style={{
                backgroundColor: "var(--nutui-gray8)",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden"
            }}>
                <div style={{
                    padding: "16px 16px 16px",
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "8px",
                    flexShrink: 0
                }}>
                    <span style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "var(--nutui-text-color)",
                        lineHeight: "1"
                    }}>
                        全国油价
                    </span>
                    <span style={{
                        fontSize: "12px",
                        marginLeft: "8px",
                        color: "var(--nutui-text-color-2)",
                        fontWeight: "normal",
                        lineHeight: "1"
                    }}>
                        {this.state.updateTime ? `更新时间：${this.state.updateTime}` : ''}
                    </span>
                </div>
                <div style={{ 
                    margin: "0 12px",
                    flex: 1,
                    overflow: "hidden",
                    borderRadius: "8px",
                    backgroundColor: "var(--nutui-gray7)"
                }}>
                    <div style={{
                        height: "100%",
                        overflow: "auto"
                    }}>
                        <Table
                            columns={[
                                {
                                    title: '省份',
                                    key: 'province',
                                    align: 'left',
                                    render: (record) => {
                                        return <span style={{ color: '#FFFFFF', fontSize: '15px', fontWeight: '600', paddingLeft: '16px' }}>{record.province}</span>
                                    }
                                },
                                {
                                    title: '#92',
                                    key: '92',
                                    align: 'center',
                                    render: (record) => {
                                        return <span style={{ color: '#E64340', fontSize: '14px', fontWeight: '500' }}>{record['92']}</span>
                                    }
                                },
                                {
                                    title: '#95',
                                    key: '95',
                                    align: 'center',
                                    render: (record) => {
                                        return <span style={{ color: '#E64340', fontSize: '14px', fontWeight: '500' }}>{record['95']}</span>
                                    }
                                },
                                {
                                    title: '#98',
                                    key: '98',
                                    align: 'center',
                                    render: (record) => {
                                        return <span style={{ color: '#E64340', fontSize: '14px', fontWeight: '500' }}>{record['98']}</span>
                                    }
                                },
                                {
                                    title: '#0',
                                    key: '0',
                                    align: 'center',
                                    render: (record) => {
                                        return <span style={{ color: '#909399', fontSize: '14px', fontWeight: '500' }}>{record['0']}</span>
                                    }
                                }
                            ]}
                            data={this.state.mData}
                            style={{
                                '--nutui-table-border-color': 'var(--nutui-gray6)',
                                '--nutui-table-cols-padding': '12px 8px',
                                '--nutui-table-tr-even-bg-color': 'var(--nutui-gray7)',
                                '--nutui-table-tr-odd-bg-color': 'var(--nutui-gray8)',
                            }}
                        />
                    </div>
                </div>
                <div style={{
                    padding: "16px",
                    textAlign: "center",
                    color: "var(--nutui-text-color-2)",
                    fontSize: "14px",
                    flexShrink: 0
                }}>
                    *数据来源互联网，以实际数据为准
                </div>
            </div>
        )
    }
}