import React, {useEffect, useState} from "react";
import {Tabs, Toast, Cell, CellGroup, Row, Col, Dialog, ConfigProvider, Table} from '@nutui/nutui-react';
import axios from "axios";
import {_baseApiUrl} from "../../Config";
import {log, toast} from "../../JSBridge";
import 'echarts/lib/chart/bar'; // 导入柱状图
import 'echarts/lib/component/tooltip'; // 导入提示框组件
import 'echarts/lib/component/title';
import {BarChart} from "../tenData/TenDataCommonV2Component";
import {appInfo, dashNeedRefresh, reloadWidget, userInfo} from "../../RubickJSBridge";
import "./Zhibiao.css";
const Zhibiao = () => {


    return (
        <div>
            <ZhibiaoDetail/>
        </div>
    );
}


function formatDate(timestamp) {
    // 确保时间戳是数字类型，并且转换为毫秒
    const timestampNum = parseInt(timestamp);
    
    // 检查是否需要转换为毫秒（如果是秒级时间戳）
    const milliseconds = timestampNum < 10000000000 
        ? timestampNum * 1000 
        : timestampNum;
    
    // 创建日期对象
    const date = new Date(milliseconds);
    
    // 检查日期是否有效
    if (isNaN(date.getTime())) {
        console.error('Invalid timestamp:', timestamp);
        return 'Invalid Date';
    }
    
    // 获取年月日
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

//指标详情
export const ZhibiaoDetail = () => {
    //详情数据
    const [data, setData] = useState({});
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

   

    if (!id) {
        return (<div></div>)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        //请求列表数据
        Toast.show({
            content: '请稍后',
            icon: 'loading',})

        axios.post(_baseApiUrl + "api/coreData/data/post", {
            "provider": "zhibiao",
            "type": "data",
            "id": id
        }).then(function (response) {
            Toast.clear()
            let mData = response.data.data
            setData(mData)
        }).catch(function (error) {
            log(error.toString())
            Toast.clear()
            toast("请求失败请重试", "error")
        })
    }, [])

    //标题
    if (!data.name) {
        return <div></div>
    }
    let chart = (<div></div>);
    let list = (<div></div>);
    if (data.dataDetail) {
        let filteredData = data.dataDetail.filter(item => item.timestamp > 0);
        let labels = filteredData.slice(0,30).map((val) => {
            return formatDate(val.timestamp);
        });
        chart = (
            <BarChart
                title={data.name + "(" + data.dataUnit + ")"} 
                color={"#749AA0"} 
                type={"bar"} 
                labels={labels}
                data={filteredData.slice(0,30).map((val) => {
                    return val.dataValue
                })}
            />
        );
        list = data.dataDetail.map((val) => {
            return (
                <Row className="list-row">
                    <Col
                        className="value-col"
                    >
                        {val.dataValue}
                    </Col>
                    <Col
                        className="date-col"
                    >
                        {formatDate(val.timestamp)}
                    </Col>
                </Row>
            )
        });
    }

    // 定义表格列
    const columns = [
        {
            title: '数值',
            key: 'value',
            align: 'left'
        },
        {
            title: '日期',
            key: 'date',
            align: 'right'
        }
    ];

    // 格式化表格数据，并过滤无效时间戳
    const tableData = data.dataDetail ? data.dataDetail
        .filter(item => item.timestamp > 0) // 过滤掉小于0的时间戳
        .map((item) => ({
            value: item.dataValue,
            date: formatDate(item.timestamp)
        })) : [];

    return (
        <div className="zhibiao-detail-container">
            <div className="zhibiao-header">
                <h1 className="zhibiao-title">{data.name}</h1>
                
                <div className="zhibiao-current-value">
                    <div className="label">当前值</div>
                    <div className="value-container">
                        <span className="value">{data.dataValue}</span>
                        <span className="unit">{data.dataUnit}</span>
                    </div>
                </div>

                <div className="zhibiao-stats">
                    <div className={`qoq-value ${data.qoq > 0 ? 'positive' : 'negative'}`}>
                        <span className="label">环比</span>
                        <span className="value">{(data.qoq * 100).toFixed(2)}%</span>
                    </div>
                    <div className="update-info">
                        <div>更新：{data.periodDate}</div>
                        <div>发布频率：{data.frequency}</div>
                    </div>
                </div>
            </div>
            {chart}

            <div className="zhibiao-detail-list">
                <div className="list-header">
                    <h2>数据明细</h2>
                    <span className="unit-tag">{data.dataUnit}</span>
                </div>
                <Table
                        columns={columns}
                        data={tableData}
                    />
            </div>

            <div className="zhibiao-footer">
                *数据来源互联网，以实际数据为准
            </div>
        </div>
    )
}


//指标菜单
export const ZhibiaoMenu = () => {
    const [tabValue, setTabValue] = useState('0');
    const [tabValue1, setTabValue1] = useState('0');
    const [indicator, setIndicator] = useState([]);

    useEffect(() => {
        //请求列表数据
        //获取数据
        Toast.show({
            content: '请稍后',
            icon: 'loading',
        })
        axios.post(_baseApiUrl + "api/coreData/data/post", {
            "provider": "zhibiao",
            "type": "indicator"
        }).then(function (response) {
            Toast.clear()
            let mData = response.data?.data?.data
            setIndicator(mData)
        }).catch(function (error) {
            log(error.toString())
            Toast.clear()
            toast("请求失败请重试", "error")
        })
    }, [])
    //添加数据到仪表盘
    let addBrick = (alias) => {

        Toast.show({
            content: '请稍后',
            icon: 'loading',
        })
        appInfo((appInfo) => {
            userInfo((userInfo) => {
                console.log(appInfo)
                console.log(userInfo)
                axios({
                    method: 'get',
                    url: _baseApiUrl + `api/coreData/userBricks/add`,
                    params: {
                        brickId:0,
                        id:-1,
                        alias: alias
                    },
                    headers:{"x-uid":userInfo.uid,"x-token":userInfo.token,"x-plat":appInfo.platform,"x-ver":appInfo.app_version}
                }).then(function (response) {
                    Toast.clear()
                    if(response.data.code === 0){
                        Toast.show({
                            content: '数据添加成功',
                            icon: 'success',
                        })
                        dashNeedRefresh()
                        reloadWidget()
                    }else{
                        Toast.show({
                            content: response.data.msg,
                            icon: 'fail',
                        })
                    }
                }).catch(function (error) {
                    log(error.toString())
                    Toast.clear()
                    toast("请求失败请重试", "error")
                })
            })
        })


    }

    //第一层,横向导航
    let items = indicator.map((item, index) => {
        //第二层，侧边导航
        let childItems = item["childrenList"].map((item, index) => {
            //第三层，子item
            let innerItems = item["childrenList"].map((item, index) => {
                return (<Cell title={item.name} isLink onClick={e => {
                    //组件别名
                    let alias = `zhibiao#${item.indicId}`
                    Dialog.alert({
                        title: '添加数据到仪表盘',
                        content: item.name,
                        onConfirm: () => {
                            console.log("添加数据到仪表盘:",alias)
                            addBrick(alias)
                        }
                    });
                }
                }/>)
            })
            return (
                <Tabs.TabPane  title={item.name} titleScroll={true} ><CellGroup>{innerItems}</CellGroup></Tabs.TabPane>)
        })
        return (<Tabs.TabPane title={item.name}  >
            <Tabs value={tabValue1} onChange={({paneKey}) => {
                setTabValue1(paneKey)
            }} titleScroll direction="vertical">
                {childItems}
            </Tabs>
        </Tabs.TabPane>)


    })
    return (
        <div style={{
            width:"100vw",
            height:"100vh",color: "#fff",
            "--nutui-tabs-tabpane-backgroundColor":"#000000"
        }
        }
            >
            <div style={{margin: "20pt", fontWeight: "bold", fontSize: "20pt"}}>指标库</div>
            <Tabs 
            style={{
            }}
            value={tabValue} 
             onChange={({paneKey}) => {
                setTabValue(paneKey)
            }}>
                {items}
            </Tabs>
        </div>
    );
};

export default Zhibiao;
