// src/Tutorial.js
import React from 'react';
import { Steps, Step } from '@nutui/nutui-react';
import './Tutorial.css';

const TutorialAndroid = () => {
    const steps = [
        {
            title: '第一步：使用MyBMW账号授权登录',
            description: <div className={'tutorial-desc'}>
                登录前，请确保车辆已经在MyBmw 中添加，且互联驾驶在有效期。
            </div>,
        },
        {
            title: '第二步：在车辆上已启用"我的行程"',
            description:  <div className={'tutorial-desc'}>
                <li>
                    1.在你还坐在你的 BMW 期间，转到"应用">"已安装的应用"，以下载最新版本的 BMW 应用。为此在 iDrive 控制器上按压右下方选项，并选择"更新应用和服务"。
                </li>
                <li>
                    2.转到"我的座驾">"设置"并选择"常规设置"。点击"数据保护"并选择"所有服务(含分析)"。如有需要，也可改为选择"个人选择"并启用"我的行程"选项。
                </li>
                <li>
                    3.前往你的 BMW 并在显示器上选择你的驾驶员档案。确保使用你的 BMW ID 登录
                </li>
            </div>,
        },
        {
            title: '第三步：忽略电池优化【不会增加电耗】',
            description:  <div className={'tutorial-desc'}>在Bimmer控制器设置页面，点击「忽略电池优化」，保持应用可以定期获取车辆信息，避免被系统杀死</div>,
        },
        {
            title: '第四步：允许应用后台启动',
            description:  <div className={'tutorial-desc'}>在Bimmer控制器设置页面，点击「设置自启动」，去系统设置页面允许后台启动和自启动，华为手机需要关闭自动管理后打开【允许后台活动】【允许自启动】</div>,
        },
        {
            title: '第五步：打开安全设置',
            description:  <div className={'tutorial-desc'}>在Bimmer控制器设置页面，点击「安全设置」开启操作安全验证 </div>
        },
        {
            title: '第六步：打开提醒设置',
            description:  <div className={'tutorial-desc'}>在Bimmer控制器设置页面，点击「提醒设置」按需开启通知提醒 </div>
        },
    ];

    document.title = "使用教程"
    
    return (
        <div className="tutorial-container" style={{
            background: '#181818',
            minHeight: '100vh',
            color: '#ffffff',
            padding: '20px'
        }}>
            <h1 style={{
                color: '#ffffff',
                fontSize: '24px',
                marginBottom: '20px'
            }}>使用教程</h1>
            <Steps dot value={1} direction="vertical" style={{
                '--nutui-steps-base-icon-color': 'rgba(255, 255, 255, 0.7)',
                '--nutui-steps-process-icon-color': '#ffffff',
                '--nutui-steps-base-title-color': 'rgba(255, 255, 255, 0.9)',
                '--nutui-steps-base-line-color': 'rgba(255, 255, 255, 0.1)',
                '--nutui-steps-content-color': 'rgba(255, 255, 255, 0.7)'
            }}>
                {steps.map((step, index) => (
                    <Step
                        value={1}
                        title={step.title}
                        description={step.description}
                        icon={step.icon}
                        style={{ marginBottom: '25px' }}
                    />
                ))}
            </Steps>
        </div>
    );
};


export default TutorialAndroid;