// src/Tutorial.js
import React from 'react';
import { Steps, Step } from '@nutui/nutui-react';
import './Tutorial.css';

const TutorialAndroidAddWidget = () => {
    const steps = [
        {
            title: '第一步：回到手机桌面',

        },
        {
            title: '第二步：双指在桌面空白处捏合缩放"',

        },
        {
            title: '第三步：找到服务卡片或小工具',
        },
        {
            title: '第四步：滑到底部找到桌面小工具',
        },
        {
            title: '第五步：找到Bimmer控制器',
        },
        {
            title: '第六步：选择需要的组件【可以滑动选择】',
        },
        {
            title: '第七步：如果是万能小组件：选择后在桌面点击组件-选择需要的组件-等待加载',
        },
        {
            title: '注意📢：万能小组件不同大小需要使用对应万能小组件',
        },
    ];

    document.title = "如何添加小组件"
    
    return (
        <div className="tutorial-container" style={{
            background: '#181818',
            minHeight: '100vh',
            color: '#ffffff',
            padding: '20px'
        }}>
            <h1 style={{
                color: '#ffffff',
                fontSize: '24px',
                marginBottom: '20px'
            }}>如何添加小组件</h1>
            <Steps dot value={1} direction="vertical" style={{
                '--nutui-steps-base-icon-color': 'rgba(255, 255, 255, 0.7)',
                '--nutui-steps-process-icon-color': '#ffffff',
                '--nutui-steps-base-title-color': 'rgba(255, 255, 255, 0.9)',
                '--nutui-steps-base-line-color': 'rgba(255, 255, 255, 0.1)',
                '--nutui-steps-content-color': 'rgba(255, 255, 255, 0.7)'
            }}>
                {steps.map((step, index) => (
                    <Step
                        value={1}
                        title={step.title}
                        description={step.description}
                        icon={step.icon}
                        style={{ marginBottom: '25px' }}
                    />
                ))}
            </Steps>
        </div>
    );
};


export default TutorialAndroidAddWidget;