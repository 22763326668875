import React, {useEffect, useState} from 'react';
import {Input, Button, Toast, Radio, Cell} from '@nutui/nutui-react';
import axios from 'axios';
import {_baseApiUrl} from "../../Config";
import {QRCodeCanvas} from "qrcode.react";

const ActivationCodeGenerator: React.FC = () => {
    const [app, setApp] = useState('bimmer');
    const [source, setSource] = useState('');
    const [days, setDays] = useState(31);
    const [customDays, setCustomDays] = useState('');
    const [password, setPassword] = useState('');
    const [generatedCode, setGeneratedCode] = useState('');

    const defTemplate = "欢迎使用Bimmer控制器\n激活码:\n#code\n\n激活链接:\nhttps://www.widgetc.cn/activation?code=#code\n\n您可以在页面中输入手机号和激活码进行激活,手机号需要已经登录过Bimmer控制器.\n输入时请确认好您的手机号是否正确,激活码使用后无法退回."
    const [template, setTemplate] = useState(defTemplate);


    useEffect(() => {
        document.title = "激活码生成"; // 设置页面标题
    }, []);

    const apps = [
        {text: 'Bimmer', value: 'bimmer'},
        {text: '魔方', value: 'mfxx'},
    ];

    const durations = [
        {text: '1个月 (31天)', value: 31},
        {text: '1年 (366天)', value: 366},
        {text: '自定义', value: 'custom'},
    ];

    const generateActivationCode = async () => {
        if (!password || password.length !== 6) {
            Toast.show({
                content: '请填写6位密码',
                icon: 'fail',
            });
            return;
        }

        let selectedDays = days === 'custom' ? parseInt(customDays, 10) : days;
        if (!selectedDays || selectedDays <= 0) {
            Toast.show({
                content: '请输入有效天数',
                icon: 'fail',
            });
            return;
        }

        try {
            Toast.show({
                content: '正在生成激活码...',
                icon: 'loading',
            });

            const response = await axios.post(
                _baseApiUrl + '/api/jihuoma/generate',
                {
                    days: selectedDays,
                    app: app,
                    source: source,
                },
                {
                    params: {token: password},
                }
            );

            if (response.data.code === 0) {
                setGeneratedCode(response.data.data);
                Toast.show({
                    content: '激活码生成成功',
                    icon: 'success',
                });
            } else {
                Toast.show({
                    content: response.data.msg || '生成失败',
                    icon: 'fail',
                });
            }
        } catch (error) {
            Toast.show({
                content: '生成失败，请检查网络或密码',
                icon: 'fail',
            });
        }
    };

    const renderTemplate = () => {
        return template.replaceAll('#code', generatedCode);
    };

    // 保存卡片图片到相册
    const saveCardImageToClipboard = async () => {
        const canvas = document.getElementById('qr-code-canvas');
        if (canvas) {
            // 创建一个图像的Blob对象
            const dataUrl = canvas.toDataURL('image/png');
            const byteString = atob(dataUrl.split(',')[1]);  // 解码Base64
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uint8Array = new Uint8Array(arrayBuffer);

            // 将字节串转换为Uint8Array
            for (let i = 0; i < byteString.length; i++) {
                uint8Array[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([uint8Array], {type: 'image/png'});
            try {
                // 将图片Blob复制到剪贴板
                await navigator.clipboard.write([
                    new ClipboardItem({
                        'image/png': blob,
                    }),
                ]);
                Toast.show({
                    content: '图片已经复制',
                    icon: 'success',
                });
            } catch (error) {
                Toast.show({
                    content: '无法将图片复制到剪贴板',
                    icon: 'fail',
                });
            }
        }
    };

    const copyCardToClipboard = () => {
        navigator.clipboard.writeText(renderTemplate()).then(() => {
            Toast.show({
                content: '内容已经复制',
                icon: 'success',
            });
        });
    };

    return (
        <div style={styles.container}>
            <h1>生成激活码</h1>
            <div style={styles.form}>
                <Cell

                    title="选择APP"
                    description={apps.find(item => item.value === app)?.text || '请选择APP'}
                    style={styles.cell}
                >
                    <Radio.Group
                        direction="horizontal"
                        value={app} onChange={(value) => setApp(value)}>
                        {apps.map((item) => (
                            <Radio
                                shape={"button"}
                                key={item.value} value={item.value}>
                                {item.text}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Cell>
                <Cell
                    title="选择时长"
                    description={days === 'custom' ? `${customDays} 天` : durations.find(item => item.value === days)?.text || '请选择时长'}
                    style={styles.cell}
                >
                    <Radio.Group
                        direction="horizontal"
                        value={days} onChange={(value) => setDays(value)}>
                        {durations.map((item) => (
                            <Radio
                                shape={"button"}
                                key={item.value} value={item.value}>
                                {item.text}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Cell>
                {days === 'custom' && (
                    <Cell>
                        <Input
                            label="自定义天数"
                            placeholder="请输入天数"
                            value={customDays}
                            onChange={(value) => setCustomDays(value)}
                            style={styles.input}
                            clearable
                        />
                    </Cell>
                )}
                <Cell>
                    <Input
                        label="source"
                        placeholder="输入来源"
                        value={source}
                        onChange={(value) => setSource(value)}
                        style={styles.input}
                    />
                </Cell>
                <Cell>
                    <Input
                        label="密码"
                        type="password"
                        placeholder="请输入密码"
                        value={password}
                        onChange={(value) => setPassword(value)}
                        style={styles.input}
                    />
                </Cell>
                <Button type="primary" block onClick={generateActivationCode} style={styles.button}>
                    生成激活码
                </Button>
            </div>
            {app === "bimmer" && <Cell
                title={"激活码地址"}
                onClick={() => {
                    navigator.clipboard.writeText("https://www.widgetc.cn/activation").then(() => {
                        Toast.show({
                            content: '激活地址复制成功',
                            icon: 'success',
                        });
                    });
                }}>https://www.widgetc.cn/activation</Cell>}
            {generatedCode && <Cell onClick={() => {
                navigator.clipboard.writeText(generatedCode).then(() => {
                    Toast.show({
                        content: '激活码复制成功',
                        icon: 'success',
                    });
                });
            }}>{generatedCode}</Cell>}
            {generatedCode && app === "bimmer" && <>
                <Cell onClick={copyCardToClipboard}>
                    <pre style={styles.pre}> {renderTemplate()}</pre>
                </Cell>
            </>}
            {app === "bimmer" && <>
                <Cell onClick={saveCardImageToClipboard}>
                    <QRCodeCanvas
                        id="qr-code-canvas"
                        marginSize={1}
                        value={`https://www.widgetc.cn/activation?code=${generatedCode}`}
                        size={240}
                        style={{
                            padding: '4px',
                            borderRadius: '8px', // 设置二维码圆角
                            marginTop: '20px',
                        }}
                    />
                </Cell>
            </>}


        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        margin: '0 auto',
    },
    form: {
        marginBottom: '20px',
    },
    cell: {
        marginBottom: '10px',
    },
    input: {
        marginBottom: '10px',
    },
    button: {
        marginTop: '20px',
    },
    textAreaContainer: {
        marginTop: '20px',
    },
    pre: {
        fontSize: '12px',
        width: '100%',
        borderRadius: '8px',
        height: '150px',
        overflowX: 'auto', wordWrap: 'break-word'
    },
    cardButton: {
        width: '100%',
        marginTop: '10px',
    },
};

export default ActivationCodeGenerator;