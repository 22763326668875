import React from 'react';
import { Collapse, CollapseItem } from '@nutui/nutui-react';

const QAAndroid = () => {
    const qaList = [
        {
            title: 'Bimmer 控制器是什么？',
            content: '是一款专门为宝马用户研发的纯粹的车控工具数据软件',
        },
        {
            title: '哪些车辆可以使用？',
            content: '您可以使用myBMW控制车辆,就可以使用这款软件,包括ID5系统及以上车型,需支持互联驾驶,目前仅支持国内车辆',
        },
        {
            title: '使用该软件是否需要互联驾驶在有效期',
            content: '需要互联驾驶在有效期',
        },
        {
            title: '使用时是否需要保持应用在前台',
            content: '不需要，但需要允许始终获取地理位置；根据地理位置变化获取车辆信息',
        },
        {
            title: '为什么有的行程只有日期',
            content: '行程根据本地获取到的车辆状态分析得出，数据不够时无法分析出行程具体时间',
        },
        {
            title: '为什么有的行程没有轨迹',
            content: '行程根据本地获取到的车辆状态分析得出，数据不够时无法计算轨迹数据',
        },

        // 你可以添加更多的 QA 项目
    ];
    document.title = "常见问题解答"

    return (
        <div style={{ 
            padding: '20px', 
            maxWidth: '800px', 
            margin: '0 auto',
            minHeight: '100vh',
            background: '#181818',
            color: '#ffffff'
        }}>
            <h1 style={{
                color: '#ffffff',
                fontSize: '24px',
                marginBottom: '20px'
            }}>常见问题解答</h1>
            <Collapse style={{
                '--nutui-collapse-item-background': '#181818',
                '--nutui-collapse-item-border-color': 'rgba(255, 255, 255, 0.1)',
                '--nutui-collapse-item-title-color': 'rgba(255, 255, 255, 0.9)',
                '--nutui-collapse-item-content-color': 'rgba(255, 255, 255, 0.7)',
                '--nutui-collapse-wrapper-content-padding': '16px'
            }}>
                {qaList.map((qa, index) => (
                    <CollapseItem 
                        title={qa.title} 
                        name={index}
                        key={index}
                    >
                        {qa.content}
                    </CollapseItem>
                ))}
            </Collapse>
        </div>
    );
};

export default QAAndroid;