import React from "react";
import "../rubick.css"
import "./Wangyiyun.css"
import {Image} from "@nutui/nutui-react";
import axios from "axios";
import {_baseApiUrl} from "../../Config";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPause, faPlay, faForward} from '@fortawesome/free-solid-svg-icons';
import {changeTitleType, eventReport, setTitle, toast} from "../../RubickJSBridge";

export class WangYiYun extends React.Component {
    constructor(props) {
        super(props);
        // const searchParams = new URLSearchParams(window.location.search);
        this.audioRef = React.createRef();
        this.wakeLock = null;

        setTitle("魔方音乐")
        changeTitleType(1)
        eventReport("pv", "music")

        this.state = {
            cover: "",
            url: "",
            songs: "",
            sings: "",
            name: "",
            comment: "",
            isPlaying: false,
            canPlay: false,
            isLoading: true,
            currentTime: 0,
            duration: 0,
            isDragging: false,
            isFirstLoad: true,
            audioInitialized: false
        }
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        this.getData(searchParams.get('id'));
        this.setupWakeLock();
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
    }

    componentDidUpdate(prevProps, prevState) {
        // 当 cover 变化时更新图标
        if (prevState.cover !== this.state.cover && this.state.cover) {
            this.setFavicon(this.state.cover);
        }
    }

    setFavicon = (url) => {
        // 移除已存在的图标
        const existingFavicon = document.querySelector("link[rel='icon']");
        if (existingFavicon) {
            document.head.removeChild(existingFavicon);
        }

        // 创建新的图标链接
        const favicon = document.createElement('link');
        favicon.type = 'image/x-icon';
        favicon.rel = 'icon';
        favicon.href = url;
        document.head.appendChild(favicon);
    };

    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
        this.releaseWakeLock();
    }

    async setupWakeLock() {
        if ('wakeLock' in navigator) {
            try {
                this.wakeLock = await navigator.wakeLock.request('screen');
                console.log('屏幕常亮已启用');
            } catch (error) {
                console.error('无法启用屏幕常亮:', error);
            }
        }
    }

    async releaseWakeLock() {
        if (this.wakeLock) {
            try {
                await this.wakeLock.release();
                console.log('屏幕常亮已释放');
            } catch (error) {
                console.error('释放屏幕常亮失败:', error);
            }
        }
    }

    getData = async (id) => {
        try {
            const response = await axios.post(_baseApiUrl + "api/coreData/data/post", {
                "provider": "wangyireping",
                "id": id
            });
            const data = response.data.data;
            this.setState({
                ...data,
                isLoading: false,
                isPlaying: !this.state.isFirstLoad,
                isFirstLoad: false
            });
            return true;
        } catch (error) {
            toast("请求失败请重试", "error");
            this.setState({ isLoading: false });
            return false;
        }
    }

    handleVisibilityChange = () => {
        if (document.visibilityState === "hidden" && this.state.isPlaying) {
            this.togglePlay();
        }
    };

    initializeAudio = () => {
        if (this.audioRef.current && !this.state.audioInitialized) {
            // 播放一个空的音频来初始化
            this.audioRef.current.play().then(() => {
                this.audioRef.current.pause();
                this.setState({ 
                    audioInitialized: true,
                    isPlaying: true  // 初始化成功后设置为播放状态
                }, () => {
                    // 初始化成功后开始实际播放
                    if (this.audioRef.current) {
                        this.audioRef.current.play().catch(error => {
                            console.log('Playback failed:', error);
                            this.setState({ isPlaying: false });
                        });
                    }
                });
            }).catch(error => {
                console.log('Audio initialization failed:', error);
                this.setState({ 
                    isPlaying: false,
                    audioInitialized: false
                });
            });
        }
    }

    togglePlay = () => {
        if (!this.state.audioInitialized) {
            // 首次点击时初始化音频
            this.initializeAudio();
            return;
        }

        const audio = this.audioRef.current;
        if (!audio || !this.state.canPlay) return;

        if (this.state.isPlaying) {
            audio.pause();
            this.setState({ isPlaying: false });  // 立即更新状态
        } else {
            audio.play().then(() => {
                this.setState({ isPlaying: true });  // 播放成功后更新状态
            }).catch(error => {
                console.log('Playback failed:', error);
                this.setState({ isPlaying: false });
            });
        }
    }

    updateSong = async () => {
        this.setState({ isLoading: true });
        const success = await this.getData();
        if (success) {
            this.setState({ 
                canPlay: false,
                isPlaying: true 
            }, () => {
                const audio = this.audioRef.current;
                if (audio) {
                    audio.load();
                }
            });
        }
    }

    handleTimeUpdate = () => {
        if (!this.state.isDragging) {
            const audio = this.audioRef.current;
            if (audio) {
                this.setState({
                    currentTime: audio.currentTime,
                    duration: audio.duration
                });
            }
        }
    }

    handleProgressClick = (e) => {
        const progressBar = e.currentTarget;
        const rect = progressBar.getBoundingClientRect();
        const ratio = (e.clientX - rect.left) / rect.width;
        const audio = this.audioRef.current;
        if (audio && audio.duration) {
            audio.currentTime = ratio * audio.duration;
        }
    }

    handleProgressDragStart = () => {
        this.setState({ isDragging: true });
    }

    handleProgressDragEnd = () => {
        this.setState({ isDragging: false });
    }

    formatTime = (time) => {
        if (!time) return "0:00";
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    render() {
        if (!this.state.url) {
            return null;
        }

        document.title = this.state.songs
        return (
            <div style={{backgroundImage: `url(${this.state.cover})`}} className="glass-container">
                <div className="glass-overlay"/>
                {this.state.isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner" />
                        <div className="loading-text">切换中...</div>
                    </div>
                )}
                <div className="content-container">
                    <div className="song-info">
                        <div className="song-title">{this.state.songs}</div>
                        <div className="song-artist">{this.state.sings}</div>
                    </div>

                    <div className="player-container">
                        <div className="play-button" onClick={this.togglePlay}>
                            {this.state.id && <audio
                                ref={this.audioRef}
                                src={this.state.url}
                                onError={() => toast("歌曲加载失败", "error")}
                                onEnded={() => {
                                    this.setState({ 
                                        isPlaying: true,
                                        audioInitialized: true
                                    });
                                    this.updateSong();
                                }}
                                onCanPlay={() => {
                                    this.setState({ canPlay: true }, () => {
                                        if (this.state.isPlaying && this.state.audioInitialized && this.audioRef.current) {
                                            this.audioRef.current.play().catch(error => {
                                                console.log('Auto-play prevented:', error);
                                                this.setState({ isPlaying: false });
                                            });
                                        }
                                    });
                                }}
                                onPause={() => this.setState({ isPlaying: false })}  // 添加暂停事件处理
                                onPlay={() => this.setState({ isPlaying: true })}    // 添加播放事件处理
                                onTimeUpdate={this.handleTimeUpdate}
                            />}
                            <FontAwesomeIcon 
                                    icon={this.state.isPlaying ? faPause : faPlay} 
                                    size="2xl"
                                />
                        </div>
                        <div className="cover-container">
                            <Image 
                                src={this.state.cover}
                                className={this.state.isPlaying ? 'rotating' : ''}
                                fit="contain"
                                radius="50%"
                            />
                        </div>
                        <Image 
                            src={require("./img/music_bg.png")}
                            className="player-background"
                        />
                    </div>

                    <div className="progress-container">
                        <div className="progress-content">
                            <div className="time-info">
                                <span>{this.formatTime(this.state.currentTime)}</span>
                                <span>{this.formatTime(this.state.duration)}</span>
                            </div>
                            <div 
                                className="progress-bar"
                                onClick={this.handleProgressClick}
                            >
                                <div 
                                    className="progress-current"
                                    style={{
                                        width: `${(this.state.currentTime / (this.state.duration || 1)) * 100}%`
                                    }}
                                />
                            </div>
                        </div>
                        <button 
                            className="next-button"
                            onClick={this.updateSong}
                        >
                            <FontAwesomeIcon 
                                icon={faForward}
                                size="lg"
                            />
                        </button>
                    </div>

                    <div className="comment-container">
                        <div className="comment-content">
                            {this.state.comment}
                            <div className="comment-author">by: {this.state.name}</div>
                        </div>
                    </div>
                </div>
                {this.state.isFirstLoad && this.state.canPlay && !this.state.audioInitialized && (
                    <div className="play-hint">
                        点击播放按钮开始播放
                    </div>
                )}
            </div>
        );
    }
}