import React, { useState, useEffect } from 'react';
import { Input, Button, Toast } from '@nutui/nutui-react';
import axios from 'axios';
import {_baseApiUrl} from "../../Config";

const ActivationPage: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [activationCode, setActivationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        document.title = "Bimmer会员激活"; // 设置页面标题
    }, []);
    useEffect(() => {
        // 接收 URL 中的参数 code 并填入激活码输入框
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            setActivationCode(code);
        }
    }, []);

    const validateInputs = () => {
        const trimmedPhone = phoneNumber.trim();
        const trimmedCode = activationCode.trim();

        if (!trimmedPhone) {
            setErrorMessage('手机号不能为空');
            return false;
        }

        const phoneRegex = /^[1][3-9]\d{9}$/;
        if (!phoneRegex.test(trimmedPhone)) {
            setErrorMessage('请输入正确的手机号');
            return false;
        }

        if (!trimmedCode) {
            setErrorMessage('激活码不能为空');
            return false;
        }

        setErrorMessage('');
        return true;
    };

    const handleActivate = async () => {
        if (!validateInputs()) {
            return;
        }

        try {
            Toast.show({
                content: '加载提示',
                icon: 'loading',
            })
            const response = await axios.post(_baseApiUrl+'/api/jihuoma/bmw', {
                phone: "86"+phoneNumber.trim(),
                code: activationCode.trim(),
            });

            Toast.clear()
            if (response.data.code === 0) {
                Toast.show({
                    content: '激活成功！祝您使用愉快',
                    icon: 'success',
                })
                setPhoneNumber('')
                setActivationCode('')
            } else {
                Toast.show({
                    content: response.data.msg || '激活失败',
                    icon: 'fail',
                })
            }
        } catch (error) {
            Toast.clear()
            Toast.show('请求失败，请稍后重试');
            console.error(error);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <img
                    src="https://images.liqucn.com/mini/120x120/img/h03/h59/img_localize_94cccf32bf9aea6ae48456c5c78b6618_512x512_120x120.png"
                    alt=""
                    style={styles.icon}
                />
                <h1 style={styles.title}>Bimmer会员激活</h1>
                <p style={styles.description}>
                    请确认手机号正确<br/>
                    手机号需要在Bimmer控制器先登录<br/>
                </p>
            </div>
            <div style={styles.form}>
                <>
                    <Input
                        label="手机号"
                        placeholder="请输入手机号"
                        value={phoneNumber}
                        onChange={(value) => setPhoneNumber(value)}
                        style={styles.input}
                        clearable
                    />
                </>
                <br />
                <>
                    <Input
                        label="激活码"
                        placeholder="请输入激活码"
                        value={activationCode}
                        onChange={(value) => setActivationCode(value)}
                        style={styles.input}
                        clearable
                    />
                </>
                {errorMessage && <div style={styles.error}>{errorMessage}</div>}
                <Button type="primary" block style={styles.button} onClick={handleActivate}>
                    激活
                </Button>
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    icon: {
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        marginBottom: '10px',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#fff',
        margin: '10px 0',
    },
    description: {
        fontSize: '14px',
        color: '#eee',
        lineHeight: '1.5',
    },
    form: {
        width: '100%',
        maxWidth: '400px',
        // backgroundColor: '#ffffff22',
        borderRadius: '10px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    input: {
        boxShadow: 'none',
        border: '1px solid #222',
        borderRadius: '5px',
        padding: '20px',
    },
    error: {
        background:'#ff000022',
        borderRadius:'10px',
        color: 'red',
        fontSize: '12px',
        margin: '10px',
        textAlign: 'center',
        padding: '10px'
    },
    button: {
        marginTop: '20px',
        height: '40px',
    },
};

export default ActivationPage;